exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-loan-js": () => import("./../../../src/pages/about-loan.js" /* webpackChunkName: "component---src-pages-about-loan-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-awards-recognition-js": () => import("./../../../src/pages/awards&recognition.js" /* webpackChunkName: "component---src-pages-awards-recognition-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-boulevard-towers-js": () => import("./../../../src/pages/boulevard-towers.js" /* webpackChunkName: "component---src-pages-boulevard-towers-js" */),
  "component---src-pages-business-park-pune-js": () => import("./../../../src/pages/business-park-pune.js" /* webpackChunkName: "component---src-pages-business-park-pune-js" */),
  "component---src-pages-buyer-tips-js": () => import("./../../../src/pages/buyer-tips.js" /* webpackChunkName: "component---src-pages-buyer-tips-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-commercial-completed-projects-js": () => import("./../../../src/pages/commercial/completed-projects.js" /* webpackChunkName: "component---src-pages-commercial-completed-projects-js" */),
  "component---src-pages-commercial-js": () => import("./../../../src/pages/commercial.js" /* webpackChunkName: "component---src-pages-commercial-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-emailer-js": () => import("./../../../src/pages/emailer.js" /* webpackChunkName: "component---src-pages-emailer-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hospitality-js": () => import("./../../../src/pages/hospitality.js" /* webpackChunkName: "component---src-pages-hospitality-js" */),
  "component---src-pages-important-notice-js": () => import("./../../../src/pages/important-notice.js" /* webpackChunkName: "component---src-pages-important-notice-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knowledge-hub-js": () => import("./../../../src/pages/knowledge-hub.js" /* webpackChunkName: "component---src-pages-knowledge-hub-js" */),
  "component---src-pages-leisure-club-js": () => import("./../../../src/pages/leisure-club.js" /* webpackChunkName: "component---src-pages-leisure-club-js" */),
  "component---src-pages-maha-rera-js": () => import("./../../../src/pages/maha-rera.js" /* webpackChunkName: "component---src-pages-maha-rera-js" */),
  "component---src-pages-management-team-js": () => import("./../../../src/pages/management-team.js" /* webpackChunkName: "component---src-pages-management-team-js" */),
  "component---src-pages-media-and-events-js": () => import("./../../../src/pages/media-and-events.js" /* webpackChunkName: "component---src-pages-media-and-events-js" */),
  "component---src-pages-nri-js": () => import("./../../../src/pages/nri.js" /* webpackChunkName: "component---src-pages-nri-js" */),
  "component---src-pages-our-verticals-js": () => import("./../../../src/pages/our-verticals.js" /* webpackChunkName: "component---src-pages-our-verticals-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-real-estate-js": () => import("./../../../src/pages/real-estate.js" /* webpackChunkName: "component---src-pages-real-estate-js" */),
  "component---src-pages-residential-completed-projects-js": () => import("./../../../src/pages/residential/completed-projects.js" /* webpackChunkName: "component---src-pages-residential-completed-projects-js" */),
  "component---src-pages-residential-js": () => import("./../../../src/pages/residential.js" /* webpackChunkName: "component---src-pages-residential-js" */),
  "component---src-pages-residential-ongoing-project-js": () => import("./../../../src/pages/residential/ongoing-project.js" /* webpackChunkName: "component---src-pages-residential-ongoing-project-js" */),
  "component---src-pages-site-map-js": () => import("./../../../src/pages/site-map.js" /* webpackChunkName: "component---src-pages-site-map-js" */),
  "component---src-pages-the-collection-js": () => import("./../../../src/pages/the-collection.js" /* webpackChunkName: "component---src-pages-the-collection-js" */),
  "component---src-pages-vantage-tower-js": () => import("./../../../src/pages/vantage-tower.js" /* webpackChunkName: "component---src-pages-vantage-tower-js" */),
  "component---src-templates-blog-post-details-js": () => import("./../../../src/templates/blogPost-details.js" /* webpackChunkName: "component---src-templates-blog-post-details-js" */),
  "component---src-templates-commercial-details-js": () => import("./../../../src/templates/commercial-details.js" /* webpackChunkName: "component---src-templates-commercial-details-js" */),
  "component---src-templates-hospitality-details-js": () => import("./../../../src/templates/hospitality-details.js" /* webpackChunkName: "component---src-templates-hospitality-details-js" */),
  "component---src-templates-leisure-club-details-js": () => import("./../../../src/templates/leisure-club-details.js" /* webpackChunkName: "component---src-templates-leisure-club-details-js" */),
  "component---src-templates-residential-details-js": () => import("./../../../src/templates/residential-details.js" /* webpackChunkName: "component---src-templates-residential-details-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/thank-you.js" /* webpackChunkName: "component---src-templates-thank-you-js" */)
}

